import $ from "jquery";

function parsePianoKey(pianoKeys, key) {

    let final = [];
    pianoKeys.map(e => {
        final[e.midiNumber] = e.key
    })

    return final[key]
}

export function simulateKeyPress(pianoKeys, key, keyType = "keydown") {
    let getKey = parsePianoKey(pianoKeys, key);

    // window.addEventListener(keyType, (e) => {
    //     console.log(e)
    // })

    // window.dispatchEvent(new KeyboardEvent(keyType, {
    //     'key': getKey
    // }));

    let index = simulationDom(getKey);
    let className = "ReactPiano__Key--active";

    if (index != undefined && index != -1) {
        let parent = $(document).find(".ReactPiano__Keyboard .ReactPiano__Key:eq(" + index + ")");
        let child = $(document).find(".ReactPiano__Keyboard .ReactPiano__Key:eq(" + index + ") .ReactPiano__NoteLabelContainer .ReactPiano__NoteLabel");

        if (keyType == "keydown") {
            parent.addClass(className);
            child.addClass(className);
        } else if (keyType == "keyup") {
            parent.removeClass(className);
            child.removeClass(className);
        }
    }
}

function simulationDom(getKey) {
    let container = $(document).find(".ReactPiano__Keyboard").find(".ReactPiano__Key");
    let containerlength = container.length;

    for (let i = 0; i < containerlength; i++) {
        let keyPress = $(document).find(".ReactPiano__Keyboard .ReactPiano__Key:eq(" + i + ") .ReactPiano__NoteLabelContainer .ReactPiano__NoteLabel");
        let text = keyPress.text();
        if (text && text != undefined && text != null) {
            if (text == getKey) {
                let index = keyPress.parent().parent().index();
                return index;
                break;
            }
        }
    }
}


export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}

export const userTypes = {
    gm: "gm",
    teacher: "teacher",
    student: "student"
}