import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Piano from "./components/index";

import io from "socket.io-client";


import { cloneSocket, socketOn } from "./socket/index";
let socket;



function App() {
  const [SessionId, setSocket] = useState("");
  const [openByUser, setUser] = useState("");
  const [toolOpen, setToolOpen] = useState(false);
  const [location, setLocation] = useState("");
  const [roomName, setRoomName] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    socket = cloneSocket();

    socket.on("id", (id) => {

      console.log("socket user ", id)
      setSocket(id);
      setUser(id);
    });

    const params = new URLSearchParams(window.location.search);
    setUrl(params.get('url')); // get Room url from query string
  }, [openByUser, SessionId])

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevToolState = usePrevious({ toolOpen });

  useEffect(() => {
    if (!prevToolState && !toolOpen) {
      setToolOpen(true);
    } else if (!prevToolState && toolOpen) {
      setToolOpen(false);
    }
  }, [toolOpen]);

  useEffect(() => {
    socket.on("openTools_return", data => {
      if (data.openTool == "piano") {
        // setToolOpen(true);
        if (!toolOpen) setToolOpen(true);
        setLocation(data.location);
        setRoomName(data.roomName);
      }
    })
    // socket.on("closetool_return", data => setToolOpen(false));
    // setToolOpen(true);

  }, [location, roomName, url]);

  const closeTool = () => {
    socket.emit("closetool", { location: location })
    setToolOpen(false);
    window.close('', '_parent', '');
  }

  return (
    <>
      {toolOpen &&
        <Piano
          socket={socket}
          Sessions={{ SessionId, openByUser }}
          // closeTool={() => closeTool()}
        />
      }
    </>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);


export default App;

